/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Text, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Výsadba"} description={"Jedna ze tří hlavních služeb"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"kontakt-0"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Subtitle className="subtitle-box" content={"Výsadba ovocných stromů a keřů<br>"}>
              </Subtitle>

              <Text className="text-box fs--24 w--500" content={"Nyní je správný čas plánovat a objednávat výsadbu ovocných stromků a keřů. Výsadbu je potřeba objednat dostatečně předem. Výsadba probíhá od konce října do doby, než začne mrznout. Pokud nemáte jasnou představu, co a kam umístit, objednejte si nejprve službu Poradenství. Některé druhy ovoce je vhodnější vysazovat na jaře.<br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/19015/05bca07c37f7448891034b978a18c0a4_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":653}} srcSet={"https://cdn.swbpg.com/t/19015/05bca07c37f7448891034b978a18c0a4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/05bca07c37f7448891034b978a18c0a4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/05bca07c37f7448891034b978a18c0a4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/05bca07c37f7448891034b978a18c0a4_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box fs--20" content={"Výsadba je možná i do velmi malých zahrad, můžeme doporučit sloupové stromky, které mohou někdy být i v jednometrovém sponu (vzdálenosti).<br>"}>
              </Text>

              <Text className="text-box" content={"<br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/19015/70f828ba4bdb4d32a0d11b3123e1e205_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":954}} srcSet={"https://cdn.swbpg.com/t/19015/70f828ba4bdb4d32a0d11b3123e1e205_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/70f828ba4bdb4d32a0d11b3123e1e205_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/70f828ba4bdb4d32a0d11b3123e1e205_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/70f828ba4bdb4d32a0d11b3123e1e205_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19015/70f828ba4bdb4d32a0d11b3123e1e205_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":513}}>
              
              <Subtitle className="subtitle-box" content={"Cena výsadby<br>"}>
              </Subtitle>

              <Text className="text-box fs--22 w--800 ls--02" content={"<b><b>Výsadba od tří ks sazenic. <br>od 1.400,- Kč/ za 1 ks ovocného stromku. <br>od 1.100 ,- Kč / za 1 ks ovocných keřů.<br>Nad 10 ks nebo u specifických požadavků individuální cena.</b><br><br></b>"}>
              </Text>

              <Text className="text-box" content={"V ceně je pořízení a dodávka stromku, vykopání jámy, dodávka kvalitní zeminy s potřebnými živinami,  úprava kořenů sazenice a výsadba do zeminy, zálivka, připevnění k podpěrnému trámku a zapěstování koruny, podle potřeby ochrany ošetření kmínku nebo opatření chráničkou.<br>"}>
              </Text>

              <Text className="text-box fs--16" content={"Cena je orientační, vždy dostanete konkrétní nabídku na konkrétní Vaše požadavky.\nPokud nemáte zcela jasnou představu, využijte naší služby"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/poradenstvi"} content={"Poradenství"}>
              </Button>

              <Text className="text-box fs--16" content={"dojedeme přímo k vám. Neváhejte nás kontaktovat a domluvit si osobní konzultaci. <br><br>Pro Vaší lepší informaci uvádíme orientační ceny:<br>Cena Výsadby ovocných stromků&nbsp; závisí na druhu a bližší specifikaci (např. kontejnerové, sloupové jsou dražší). <br><br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/19015/42145235905c4e958767107a64f277cc_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":331}} srcSet={"https://cdn.swbpg.com/t/19015/42145235905c4e958767107a64f277cc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/42145235905c4e958767107a64f277cc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/42145235905c4e958767107a64f277cc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/42145235905c4e958767107a64f277cc_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--30" columns={"4"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Výsadba<br>&amp; řez ovocných stromků<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Hlavní 68, Frýdlant nad Ostravicí</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+420 777 707 360<br>ivo.vantuch@email.cz"}>
              </Text>

              <Text className="text-box" content={"<br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}